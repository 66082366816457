import { Component, OnInit } from '@angular/core';
import { ScrollService } from '../scroll.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit  {
  menuOpened = false;
  constructor( private scrollService: ScrollService  ) { }

  ngOnInit() {
    this.scrollService.scrolling.subscribe((position) => {
      let color = document.documentElement.style.getPropertyValue('--header-background');
      color = color.substring(0, 7);
      if ( position < 200 ) {
        color += '26';
      }
      document.documentElement.style.setProperty('--header-background', color);
     });
  }

  scrollTo( tag: string) {
    console.log('Scrolling to tag: ' + '[section=' + tag + ']' + ' menuOpened ' + this.menuOpened );
    const offset = this.menuOpened ? 0 : 0;
    this.scrollService.scrollTo('[section=' + tag + ']', offset );
    this.closeMenu();
  }

  toggleMenu() {
    this.menuOpened = !this.menuOpened;
  }

  closeMenu() {
    this.menuOpened = false;
  }
}
