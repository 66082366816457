import { Component, OnInit   } from '@angular/core';
import { animate, trigger, state, style, transition } from '@angular/animations';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  animations: [
    trigger('progress', [
    // ...
    state('start', style({
      width: '100%'
    })),
    transition('void => *', [
      animate(1000, style({
        width: '100%' }))
    ]),
  ]),
  ]
})
export class LoaderComponent implements OnInit  {
  loaded = false;

  constructor() {
  }

  ngOnInit() {
    this.loaded = true;
  }
}
