import { Component, OnInit, ViewChild } from '@angular/core';
import { ThemeService } from './theme.service';
import { ActivatedRoute } from '@angular/router';
import { ModalComponent } from './modal/modal.component';
import { ScrollService } from './scroll.service';

declare var WOW: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Papacorn';

  @ViewChild(ModalComponent, {static: false}) privacyModal: ModalComponent;


  constructor(private themeService: ThemeService, private route: ActivatedRoute, private scroller: ScrollService) {
  }

  ngOnInit() {
    this.route.queryParams
    .subscribe(params => {
      if ( params.privacy ) {
        // display the privacy modal.
        this.privacyModal.openModal();
      }
    });
    new WOW(
      /*{
      mobile:       false,
      }*/
      ).init();
    this.themeService.onInit();
  }

  print() {
    var printContents = document.getElementById('printablePolicy').innerHTML;
    const newWindow = window.open('', '_blank');
    newWindow.document.body.innerHTML = printContents;
    newWindow.print();
  }


}
