import { Component, OnInit, Input } from '@angular/core';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  @Input() modal: ModalComponent;

  constructor() { }

  ngOnInit() {
  }

  openURL(url: string) {
    window.open(url , '_blank');
  }

}
