import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-appstore',
  templateUrl: './appstore.component.html',
  styleUrls: ['./appstore.component.scss']
})
export class AppstoreComponent implements OnInit {

  @Input() ios: string;
  @Input() android: string;

  constructor() {
    this.ios = 'ios';
    this.android = 'android';
  }

  ngOnInit() {
  }

  // redirct to app stores when buttons are pressed.
  iosClick() {
    console.log('Redirecting to Apple app store: ' + this.ios);
    this.openURL(this.ios);
  }

  androidClick() {
    console.log('Redirecting to google play: ' + this.android);
    this.openURL(this.android);

  }

  openURL(url: string) {
    window.open(url , '_blank');
  }

}
