import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

  @Input() title: string;
  @Input() titleText: string;
  @Input() section: string;
  isHidden: boolean;
  opening: boolean;
  closing: boolean;

  constructor() {
    this.title = 'title';
    this.isHidden = true;
    this.opening = false;
    this.closing = false;
  }

  ngOnInit() {
  }

  closeModal() {
    this.opening = false;
    this.closing = true;
    setTimeout( () => {
      console.log('Hiding modal dialog: ' + this.title);
      this.isHidden = true;
    }, 1000);
  }

  openModal() {
    console.log('Opening modal dialog: ' + this.title);
    this.closing = false;
    this.isHidden = false;
    this.opening = true;
    return false;
  }
}
