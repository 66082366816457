import { Injectable, EventEmitter } from '@angular/core';


// note using var( url ) with background image causes flicker with dev-tools open (?cache problem)
export const darkTheme = {
  'background-color': '#0a0a0a',
  'primary-color': '#dddddd',
  'secondary-color': '#F7941E',
  'tertiary-color': '#eaeada',
  'error-color': '#a72c2c',
  'button-bg': '#333',
  'button-store': '#111',
  'button-store-bg': '#ddd',
  'button-store-hover': '#fff',
  'button-store-hover-bg': '#111',
  'background-image': 'url(\'./assets/images/bg-dark.png\')',
  'modal-header-image': 'url(\'./assets/images/modal-header-bg-dark.png\')',
  'card-background':  '#00000026',
  'header-background': '#00000026',
  'modal-background' : '#000000cc',
  'logo-image': 'url(\'./assets/images/logo.png\')',
  'logo-header': 'url(\'./assets/images/logo.png\')',
};

export const lightTheme = {
  'background-color': '#eeeeee',
  'primary-color': '#0a0a0a',
  'secondary-color': '#F7941E',
  'tertiary-color': '#343d4b',
  'error-color': '#bb0000',
  'button-bg': '#bbb',
  'button-store': '#fff',
  'button-store-bg': '#111',
  'button-store-hover': '#111',
  'button-store-hover-bg': '#ccc',
  'background-image': 'none',
  'card-background':  '#eeeeee26',
  'header-background': '#eeeeee26',
  'modal-background' : '#00000050',
  'modal-header-image': 'url(\'./assets/images/modal-header-bg-light.png\')',
  'logo-image': 'url(\'./assets/images/logo.png\')',
  'logo-header': 'url(\'./assets/images/logo-header.png\')',
};

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  currentTheme: {};
  themeChangedEmitter = new EventEmitter();

  constructor() {
  }

  onInit() {
    // set the theme according to the UI's settings (light or dark)
    if (window.matchMedia('(prefers-color-scheme)').media !== 'not all') {
      console.log('Dark mode is supported.');
      // set a listener so we know when to change
      const mql = window.matchMedia('(prefers-color-scheme: dark)');
      try {
        mql.addEventListener('change', (e) => {
          console.log('Color scheme changed.');
          this.setThemeUI();
          this.themeChangedEmitter.emit('themeChanged');
        });
      } catch(error) {
        mql.addListener(() => {
          console.log('Color scheme changed.');
          this.setThemeUI();
          this.themeChangedEmitter.emit('themeChanged');
        });
      }
    }
    this.setThemeUI();
  }

  getPrimaryColor() {
    console.log('Requested primary color: ' + this.currentTheme['primary-color']);
    return this.currentTheme['primary-color'];
  }

  getSecondaryColor() {
    console.log('Requested secondary color: ' + this.currentTheme['secondary-color']);
    return this.currentTheme['secondary-color'];
  }

  getTertiaryColor() {
    console.log('Requested tertiary color: ' + this.currentTheme['tertiary-color']);
    return this.currentTheme['tertiary-color'];
  }

  getBackgroundColor() {
    console.log('Requested background color: ' + this.currentTheme['background-color']);
    return this.currentTheme['background-color'];
  }

  setThemeUI() {
    if ( window.matchMedia('(prefers-color-scheme: dark)').matches ) {
      console.log('Dark mode is enabled.');
      this.setDarkTheme();
    } else {
      console.log('Light mode is enabled.');
      this.setLightTheme();
    }
  }

  setDarkTheme() {
    this.setTheme(darkTheme);
  }

  setLightTheme() {
    this.setTheme(lightTheme);
  }

  private setTheme(theme: {}) {
    this.currentTheme = theme;
    Object.keys(theme).forEach(k =>
      document.documentElement.style.setProperty(`--${k}`, theme[k])
    );
    document.documentElement.style.setProperty('--background-image', theme['background-image']);
  }
}
