import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss']
})
export class ContainerComponent implements OnInit {

  @Input() title: string;
  @Input() section: string;

  constructor() {
    this.title = 'title';
    this.section = 'section';
  }

  ngOnInit() {
  }

}
