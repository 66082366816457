import { Injectable, EventEmitter } from '@angular/core';
import { fromEvent  } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScrollService {
  scrolling = new EventEmitter<any>();
  scrollPosition = 0;
  ticking = false;

  constructor() {
    fromEvent(window, 'scroll').subscribe(e => {
      this.onWindowScroll(e);
  });
  }

  scrollTo( tag: string, offset = 0 ) {
    const el = document.querySelector(tag);
    //el.scrollIntoView({behavior: 'smooth', block: 'start'});
    const rect = el.getBoundingClientRect();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    //console.log('scrolling: ' + (rect.top + scrollTop + offset));
    window.scrollTo(
    {
      top: rect.top + scrollTop + offset,
      left: 0,
      behavior: 'smooth'
    });
  }

  onWindowScroll(e) {
    this.scrollPosition = window.scrollY;

    if (!this.ticking) {
      window.requestAnimationFrame( () => {
        this.scrolling.emit(this.scrollPosition);
        this.ticking = false;
      });
      this.ticking = true;
    }
  }
}
