import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';

import { environment } from '../environments/environment';

@Injectable()
export class ContactService {

  constructor(private http: HttpClient) { }

  sendMail(contact): Observable<object> {
    // Setting json header so other applications can parse our message.
    const httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(environment.target, JSON.stringify(contact), { headers: httpHeaders })
    .pipe(
      // When it fails, we're going to retry 1 times.
      retry(1)
    );
  }
}
